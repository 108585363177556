import React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { MdxArticle, SanityPost, CloudinaryImage } from '../types/Article';
import { SiteMetadata } from '../types/SiteMetadata';
import Content from '../layouts/main';
import styled from '../styled';
import { Typography, Tooltip, IconButton } from '@material-ui/core';
import { Linkedin, Facebook, Twitter, Globe, Instagram } from 'react-feather';
import { SEO } from '../components/SEO';
import { ResponsiveImage } from '../components/ResponsiveImage';
import {
  BlockContent,
  sanitySerializers
} from '../components/SanityBlockContent';

type IAuthor = {
  name: string;
  twitter?: string;
  instagram?: string;
  image: string;
  message: string | React.ReactNode;
};

interface IAuthorHash {
  [key: string]: IAuthor;
}

const AUTHORS: IAuthorHash = {
  monica: {
    name: 'Monica Lent',
    message:
      "Co-founder of Affilimate. I'm here to share data-driven tips and strategies for earning more from your website as a content publisher.",
    twitter: 'monicalent',
    image: 'blog/authors/monica.jpg'
  },
  jules: {
    name: 'Jules Costa',
    instagram: 'julesontap',
    message:
      'Jules Costa is a Brazilian-American freelance writer and money expert who has been featured on NPR, Personal Capital and Boston 25.',
    image: 'blog/authors/jules.jpg'
  },
  lily: {
    name: 'Lily Ugbaja',
    twitter: 'lilyugbaja',
    message: (
      <>
        Lily is a B2B SaaS and Marketing Writer for hire who is passionate about
        creating data-driven SEO content that ranks, inspires brand trust, and
        drives conversions. Connect with her on LinkedIn and Twitter or on her
        website{' '}
        <a href="https://lilyugbaja.com" target="_blank">
          lilyugbaja.com
        </a>
        .
      </>
    ),
    image: 'blog/authors/lily.jpg'
  },
  sofie: {
    name: 'Sofie Couwenbergh',
    message: (
      <>
        Copywriter and content strategiest at{' '}
        <a href="https://letmewritethatdownforyou.com" target="_blank">
          Let Me Write That Down For You
        </a>{' '}
        and travel blogger at{' '}
        <a href="https://wonderfulwanders.com" target="_blank">
          Wonderful Wanderings
        </a>
        .
      </>
    ),
    twitter: 'scouwenbergh',
    image: 'blog/authors/sofie.jpg'
  },
  disha: {
    name: 'Disha Sharma',
    message: (
      <>
        Disha is a contributing writer at Affilimate. She writes extensively on
        topics that help publishers connect with their readers.
      </>
    ),
    image: 'blog/authors/disha.jpg'
  },
  deb: {
    name: 'Deb Mukherjee',
    twitter: 'debgotwired',
    message: (
      <>
        Deb Mukherjee is a veteran marketer for early-stage SaaS startups. To
        date, he has helped over 40 companies grow with organic acquisition
        strategies. Now, he freelances with tech companies as a writer & content
        strategist. You can also find him tweeting or{' '}
        <a href="https://debgotwired.com/blog" target="_blank">
          blogging
        </a>{' '}
        his thoughts occasionally.
      </>
    ),
    image: 'blog/authors/deb.jpg'
  },
  benjamin: {
    name: 'Benjamin Houy',
    message: (
      <>
        Founder of{' '}
        <a href="https://frenchtogether.com" target="_blank" rel="noopener">
          French Together
        </a>{' '}
        where I help 317,927 monthly readers learn the 20% of French needed to
        understand 80% of conversations. I also help bloggers get more traffic
        at{' '}
        <a href="https://growwithless.com" target="_blank" rel="noopener">
          Grow With Less
        </a>
        .
      </>
    ),
    twitter: 'growwithless',
    image: 'blog/authors/benjamin.jpg'
  }
};

type PageQueryData = {
  mdx: MdxArticle;
  sanityPost: SanityPost;
  site: {
    siteMetadata: SiteMetadata;
  };
  allMdx: {
    edges: {
      node: MdxArticle;
    }[];
  };
};

export const pageQuery = graphql`
  query($postId: String) {
    sanityPost(id: { eq: $postId }) {
      id
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      title
      description
      publishedAt(formatString: "YYYY-MM-DD")
      lastUpdated(formatString: "YYYY-MM-DD")
      author {
        _rawBio
        name
        twitter
        jobTitle
        website
        linkedin
        instagram
        slug {
          current
        }
        image {
          alt
          image {
            width
            height
            src
          }
        }
      }
      mainImage {
        image {
          src
          width
          height
        }
        alt
      }
    }
    mdx(id: { eq: $postId }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        date
        author
        summary
        image
        socialSharingImage
        tweet
        pinterestImage
        seoTitle
        seoDescription
      }
    }
  }
`;

const Title = styled('h1')`
  color: ${(p) => p.theme.palette.grey[900]};
  font-size: 36px;

  @media (max-width: 800px) {
    font-size: 28px;
  }
`;

const PostMeta = styled('div')`
  color: ${(p) => p.theme.palette.grey.A700};
  margin-bottom: ${(p) => p.theme.spacing(4)}px;

  img {
    position: relative;
    border: 3px solid white;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
    top: 10px;
    display: inline-block;
    width: 36px;
    border-radius: 100%;
    margin-right: 8px;
  }
`;

const COPY_FONT_SIZE = '18px';
const COPY_LINE_HEIGHT = '36px';

const Article = styled('article')`
  p {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    a {
      border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
      color: ${(p) => p.theme.palette.primary.main};
      transition: 0.1s border-bottom linear;
      font-size: ${COPY_FONT_SIZE};
      line-height: ${COPY_LINE_HEIGHT};

      &:hover {
        border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
      }
    }
    code {
      display: inline-block;
    }
  }

  blockquote {
    padding-left: ${(p) => p.theme.spacing(3)}px;
    border-left: 3px solid #ddd;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};
    margin: 0;
    font-style: italic;
    color: #666;

    p {
      font-size: ${COPY_FONT_SIZE};
      line-height: ${COPY_LINE_HEIGHT};
      margin-bottom: 0;
    }
  }

  code {
    display: block;
    background-color: #eee;
    padding: 0 6px;
    border-radius: 4px;
  }

  & div > p:first-of-type,
  & > p:first-of-type {
    font-size: 24px;
    line-height: 36px;

    a {
      font-size: inherit;
    }
  }

  img {
    border: 1px solid #eee;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    max-width: 100%;
    height: auto;
    margin: ${(p) => p.theme.spacing(3)}px auto ${(p) => p.theme.spacing(3)}px;
  }
`;

const FeaturedImage = styled('div')`
  max-width: 950px;
  margin: 90px auto 0;

  @media (max-width: 800px) {
    margin: 56px auto 0;
  }
`;

const ArticleWrapper = styled('div')`
  margin: 0 auto ${(p) => p.theme.spacing(4)}px;
  display: grid;
  grid-template-columns: 650px 1fr;
  grid-column-gap: 60px;
  max-width: 950px;
  padding: 0 12px;

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }

  ul,
  ol {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    code {
      display: inline-block;
    }

    a {
      border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
      color: ${(p) => p.theme.palette.primary.main};
      transition: 0.1s border-bottom linear;
      font-size: ${COPY_FONT_SIZE};
      line-height: ${COPY_LINE_HEIGHT};

      &:hover {
        border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
      }
    }
  }
`;

const A = styled('a')`
  border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};
  transition: 0.1s border-bottom linear;
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  &:hover {
    border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
  }

  p,
  ol,
  li & {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};
  }
`;

const Ol = styled('ol')`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  code {
    display: inline-block;
  }
`;

const Ul = styled('ul')`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  code {
    display: inline-block;
  }

  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
    transition: 0.1s border-bottom linear;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    &:hover {
      border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
    }
  }
`;

const Code = styled('code')`
  display: block;
  font-family: Consolas, Menlo, Courier, monospace;
  background-color: #efefef;
  border-radius: 8px;
  padding: 0 12px;
  font-size: 0.9em;
  line-height: 1.5em;
  overflow-x: auto;
  max-height: 200px;
`;

const P = styled(Typography)`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
    transition: 0.1s border-bottom linear;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    &:hover {
      border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
    }
  }

  code {
    font-family: Consolas, Menlo, Courier, monospace;
    background-color: #efefef;
    border-radius: 8px;
    padding: 0 12px;
    font-size: 0.9em;
    word-break: break-all;
  }
`;

const Blockquote = styled('blockquote')`
  margin: ${(p) => p.theme.spacing(2)}px 0;
  padding: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(2)}px;
  background-color: #f9f9f9;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  border: 1px dashed #ddd;
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  p {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};
    margin-bottom: 0;
  }
`;

const H1 = styled('h1')`
  margin-top: ${(p) => p.theme.spacing(6)}px;
`;

const H2 = styled('h2')`
  font-size: 30px;
  margin-top: ${(p) => p.theme.spacing(6)}px;
  @media (max-width: 800px) {
    font-size: 24px;
  }
`;

const H3 = styled('h3')`
  font-size: 28px;
  margin-top: ${(p) => p.theme.spacing(6)}px;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

const H4 = styled('h4')`
  font-size: 24px;
  margin-top: ${(p) => p.theme.spacing(6)}px;
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

const Table = styled('table')`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const Pre = styled('pre')`
  overflow-x: scroll;
  white-space: pre-wrap;
  word-break: break-word;
`;

const mdxComponents = {
  a: A,
  p: (props: any) => <P variant="body1" {...props} />,
  blockquote: Blockquote,
  ol: Ol,
  pre: Pre,
  table: Table,
  ul: Ul,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  code: Code
};

const AuthorWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(28)}px;
  color: ${(p) => p.theme.palette.grey.A400};

  p a {
    border-bottom: 1px solid;
  }

  @media (max-width: 800px) {
    margin-top: ${(p) => p.theme.spacing(2)}px;
    display: block;
    justify-content: center;
    text-align: center;
  }
`;

const AuthorImage = styled('img')`
  width: 85px;
  border-radius: 100%;
  margin-right: ${(p) => p.theme.spacing(4)}px;
  border: 3px solid white;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));

  @media (max-width: 800px) {
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

const AuthorName = styled(Typography)`
  font-weight: 700;
  margin-top: ${(p) => p.theme.spacing(1)}px;

  a {
    border: 0 !important;

    &:hover {
      color: ${(p) => p.theme.palette.primary.main};
    }
  }
`;

const SocialWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${(p) => p.theme.spacing(1)}px;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const SocialIcon = styled('a')`
  display: inline-block;
  font-size: 12px;
  transition: all ease-out 0.15s;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  color: white;
  padding: ${(p) => p.theme.spacing(1)}px;
  width: 36px;
  height: 36px;
  border-radius: ${(p) => p.theme.custom.borderRadius.unit}px;

  &:hover {
    transform: scale(1.25);
  }
`;

const SanityAuthor = ({
  name,
  slug,
  _rawBio,
  instagram,
  twitter,
  website,
  linkedin,
  image
}: {
  name: string;
  _rawBio: any[];
  instagram?: string;
  twitter?: string;
  website?: string;
  linkedin?: string;
  slug: { current: string };
  image: CloudinaryImage;
}) => {
  const matches = new RegExp(/v[0-9]+\/(.+)/).exec(image.image.src);
  if (!matches) {
    return null;
  }
  const src = matches[1];
  const ASSET_URL = 'https://assets.affilimate.com';
  const toSize = (size: number, src: string) => {
    return `${ASSET_URL}/image/upload/w_${size}/${src}`;
  };

  const fullSrc = `${ASSET_URL}/${src}`;
  const webPSrc = src.replace('.png', '.webp').replace('.jpg', '.webp');
  const jpgSrc = src.replace('.png', '.jpg');

  return (
    <AuthorWrapper>
      <picture>
        <source
          type="image/webp"
          srcSet={`${toSize(170, webPSrc)} 170w, ${toSize(85, webPSrc)} 85w`}
          sizes="100wv"
        />
        <source
          type="image/jpeg"
          srcSet={`${toSize(170, jpgSrc)} 170w, ${toSize(85, jpgSrc)} 85w`}
          sizes="100wv"
        />
        <AuthorImage
          src={fullSrc}
          alt={name}
          loading="lazy"
          width="85"
          height="85"
        />
      </picture>
      <div>
        <AuthorName
          variant="h6"
          component="p"
          style={{ display: 'inline-block', marginRight: '6px' }}
        >
          <Link to={`/blog/authors/${slug.current}/`}>{name}</Link>
        </AuthorName>
        <Typography
          variant="body1"
          component="p"
          color="textSecondary"
          style={{ fontSize: '16px' }}
        >
          <BlockContent blocks={_rawBio} serializers={sanitySerializers} />
        </Typography>
        <div>
          {website && (
            <IconButton href={website} target="_blank">
              <Globe size={14} />
            </IconButton>
          )}
          {twitter && (
            <IconButton
              href={`https://twitter.com/${twitter}`}
              target="_blank"
              rel="nofollow"
            >
              <Twitter size={14} />
            </IconButton>
          )}
          {instagram && (
            <IconButton
              href={`https://instagram.com/${instagram}`}
              target="_blank"
              rel="nofollow"
            >
              <Instagram size={14} />
            </IconButton>
          )}
          {linkedin && (
            <IconButton href={linkedin} target="_blank" rel="nofollow">
              <Linkedin size={14} />
            </IconButton>
          )}
        </div>
      </div>
      <br />
      <Typography variant="caption" color="textSecondary">
        <strong>SHARE THIS POST</strong>
      </Typography>
    </AuthorWrapper>
  );
};

const Author = ({ name }: { name: string }) => {
  const author = AUTHORS[name];

  if (!author) {
    return null;
  }

  const ASSET_URL = 'https://assets.affilimate.com';
  const toSize = (size: number, src: string) => {
    return `${ASSET_URL}/image/upload/w_${size}/${src}`;
  };

  const src = author.image;
  const fullSrc = `${ASSET_URL}/${src}`;
  const webPSrc = src.replace('.png', '.webp').replace('.jpg', '.webp');
  const jpgSrc = src.replace('.png', '.jpg');

  return (
    <AuthorWrapper>
      <picture>
        <source
          type="image/webp"
          srcSet={`${toSize(170, webPSrc)} 170w, ${toSize(85, webPSrc)} 85w`}
          sizes="100wv"
        />
        <source
          type="image/jpeg"
          srcSet={`${toSize(170, jpgSrc)} 170w, ${toSize(85, jpgSrc)} 85w`}
          sizes="100wv"
        />
        <AuthorImage
          src={fullSrc}
          alt={author.name}
          loading="lazy"
          width="85"
          height="85"
        />
      </picture>
      <div>
        <div>
          <AuthorName
            variant="h6"
            component="p"
            style={{ display: 'inline-block', marginRight: '6px' }}
          >
            {author.name}
          </AuthorName>
          {author.instagram && (
            <>
              (
              <a
                href={`https://instagram.com/${author.instagram}`}
                target="_blank"
                rel="nofollow"
              >
                @{author.instagram}
              </a>
              )
            </>
          )}
          {author.twitter && (
            <>
              (
              <a
                href={`https://twitter.com/${author.twitter}`}
                target="_blank"
                rel="nofollow"
              >
                @{author.twitter}
              </a>
              )
            </>
          )}
        </div>
        <Typography
          variant="body1"
          component="p"
          color="textSecondary"
          style={{ fontSize: '16px' }}
        >
          {author.message}
        </Typography>
      </div>
      <br />
      <Typography variant="caption" color="textSecondary">
        <strong>SHARE THIS POST</strong>
      </Typography>
    </AuthorWrapper>
  );
};

type IconProps = {
  color?: string;
  size?: string | number;
};

const SocialShareButton = ({
  color,
  tooltip,
  icon: IconComponent,
  ...props
}: {
  color: string;
  icon: React.ComponentType<IconProps>;
  tooltip: string;
} & React.HTMLProps<HTMLAnchorElement>) => (
  <Tooltip title={tooltip} placement="bottom">
    <SocialIcon href="#" style={{ backgroundColor: color }} {...props}>
      <IconComponent size={20} />
    </SocialIcon>
  </Tooltip>
);

const SanitySocialSharing = ({ post }: { post: SanityPost }) => {
  const postUrl = encodeURIComponent(
    `https://affilimate.com/blog/${post.slug.current}/`
  );
  const tweet = encodeURIComponent(`${post.socialMessage} | via @affilimateio`);
  const twitterHref = `https://twitter.com/share?text=${tweet}&url=${postUrl}`;
  const facebookHref = `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`;
  const linkedInHref = `https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`;

  return (
    <SocialWrapper>
      <SocialShareButton
        color="#3c5a98"
        tooltip="Share on Facebook"
        href={facebookHref}
        icon={Facebook}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(facebookHref, 'facebook-share', 'width=550,height=235');
        }}
      />
      <SocialShareButton
        color="#3ea9dd"
        tooltip="Tweet this"
        icon={Twitter}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(twitterHref, 'twitter-share', 'width=550,height=235');
        }}
        href={twitterHref}
      />
      <SocialShareButton
        color="#0077B5"
        tooltip="Share on LinkedIn"
        icon={Linkedin}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(linkedInHref, 'linkedin-share', 'width=550,height=235');
        }}
        href={linkedInHref}
      />
    </SocialWrapper>
  );
};

const SocialSharing = ({ post }: { post: MdxArticle }) => {
  const { frontmatter, fields } = post;
  const postUrl = encodeURIComponent('https://affilimate.com' + fields.slug);
  const tweet = encodeURIComponent(frontmatter.tweet + ' | via @affilimateio');
  const twitterHref = `https://twitter.com/share?text=${tweet}&url=${postUrl}`;
  const facebookHref = `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`;
  const linkedInHref = `https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`;

  return (
    <SocialWrapper>
      <SocialShareButton
        color="#3c5a98"
        tooltip="Share on Facebook"
        href={facebookHref}
        icon={Facebook}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(facebookHref, 'facebook-share', 'width=550,height=235');
        }}
      />
      <SocialShareButton
        color="#3ea9dd"
        tooltip="Tweet this"
        icon={Twitter}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(twitterHref, 'twitter-share', 'width=550,height=235');
        }}
        href={twitterHref}
      />
      <SocialShareButton
        color="#0077B5"
        tooltip="Share on LinkedIn"
        icon={Linkedin}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(linkedInHref, 'linkedin-share', 'width=550,height=235');
        }}
        href={linkedInHref}
      />
    </SocialWrapper>
  );
};

const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

export default function Template({ data }: { data: PageQueryData }) {
  const { mdx, sanityPost } = data;

  if (sanityPost) {
    const matches = sanityPost.mainImage?.image
      ? new RegExp(/v[0-9]+\/(.+)/).exec(sanityPost.mainImage.image.src)
      : false;
    const sharingPath = matches ? matches[1] : '';
    const sharingImage = `/images/${sharingPath}`;

    return (
      <Content>
        <>
          <SEO
            title={sanityPost.title}
            description={sanityPost.description}
            image={sharingImage}
            pathname={`/blog/${sanityPost.slug.current}/`}
            isArticle={true}
            publishedDate={sanityPost.publishedAt}
            lastUpdated={sanityPost.lastUpdated || sanityPost.publishedAt}
            author={sanityPost.author}
          />
          <FeaturedImage>
            {sanityPost.mainImage && sanityPost.mainImage.image && (
              <ResponsiveImage
                src={sanityPost.mainImage.image.src}
                alt={sanityPost.mainImage.alt}
                style={{ maxWidth: '100%', height: 'auto' }}
                width="1000"
                height="500"
                loading="eager"
              />
            )}
          </FeaturedImage>
          <ArticleWrapper>
            <Article>
              <Title>{sanityPost.title}</Title>
              <PostMeta>
                By {sanityPost.author.name} · Updated{' '}
                {formatDate(sanityPost.lastUpdated || sanityPost.publishedAt)}
              </PostMeta>
              <BlockContent
                blocks={sanityPost._rawBody}
                serializers={sanitySerializers}
              />
            </Article>
            <div>
              <SanityAuthor {...sanityPost.author} />
              <SanitySocialSharing post={sanityPost} />
              <br />
            </div>
          </ArticleWrapper>
        </>
      </Content>
    );
  }

  const { frontmatter, fields } = mdx;

  return (
    <Content>
      <>
        <SEO
          title={frontmatter.seoTitle || frontmatter.title}
          description={frontmatter.seoDescription || frontmatter.summary}
          image={frontmatter.socialSharingImage}
          pathname={fields.slug}
          isArticle={true}
          publishedDate={frontmatter.date}
        />
        <FeaturedImage>
          <ResponsiveImage
            src={frontmatter.image}
            alt={frontmatter.seoTitle || frontmatter.title}
            style={{ maxWidth: '100%', height: 'auto' }}
            width="1000"
            height="500"
            loading="eager"
          />
        </FeaturedImage>
        <ArticleWrapper>
          <Article>
            <Title>{frontmatter.title}</Title>
            <PostMeta>
              By {AUTHORS[frontmatter.author].name} · Updated{' '}
              {formatDate(frontmatter.date)}
            </PostMeta>
            <MDXProvider components={mdxComponents}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </Article>
          <div>
            <Author name={frontmatter.author} />
            <SocialSharing post={mdx} />
          </div>
        </ArticleWrapper>
      </>
    </Content>
  );
}
